import { range, orderBy, map } from 'lodash'

export const monthList = [
  { name: 'jan', value: 1 },
  { name: 'feb', value: 2 },
  { name: 'mar', value: 3 },
  { name: 'apr', value: 4 },
  { name: 'may', value: 5 },
  { name: 'jun', value: 6 },
  { name: 'jul', value: 7 },
  { name: 'aug', value: 8 },
  { name: 'sep', value: 9 },
  { name: 'oct', value: 10 },
  { name: 'nov', value: 11 },
  { name: 'dec', value: 12 }
]

export const LEAVE_YEAR_CONFIG = {
  START: -1,
  END: 5
}

// tranform from Jan === 0 to Jan === 1
export const getCurrentMonth = +new Date().getMonth() + 1

export const getCurrentYear = new Date().getFullYear()

const yearFormENV = () => {
  const { REACT_APP_INITIAL_YEAR = 2007 } = process.env
  if (REACT_APP_INITIAL_YEAR) {
    return orderBy(
      range(REACT_APP_INITIAL_YEAR, +getCurrentYear + 1).map((year) => {
        return { value: year, label: year.toString() }
      }),
      ['value'],
      ['desc']
    )
  }
  return []
}

export const leavePeriodYear = () => {
  const { REACT_APP_LEAVE_PERIOD_COUNT = 5 } = process.env

  return map(
    range(
      getCurrentYear,
      getCurrentYear + Number(REACT_APP_LEAVE_PERIOD_COUNT)
    ),
    (item) => ({ label: item, value: item })
  )
}

export const leavePeriodYearList = [...leavePeriodYear()]
export const yearList = [...yearFormENV()]

export const dateList = [
  { name: '1', value: 1 },
  { name: '2', value: 2 },
  { name: '3', value: 3 },
  { name: '4', value: 4 },
  { name: '5', value: 5 },
  { name: '6', value: 6 },
  { name: '7', value: 7 },
  { name: '8', value: 8 },
  { name: '9', value: 9 },
  { name: '10', value: 10 },
  { name: '11', value: 11 },
  { name: '12', value: 12 },
  { name: '13', value: 13 },
  { name: '14', value: 14 },
  { name: '15', value: 15 },
  { name: '16', value: 16 },
  { name: '17', value: 17 },
  { name: '18', value: 18 },
  { name: '19', value: 19 },
  { name: '20', value: 20 },
  { name: '21', value: 21 },
  { name: '22', value: 22 },
  { name: '23', value: 23 },
  { name: '24', value: 24 },
  { name: '25', value: 25 },
  { name: '26', value: 26 },
  { name: '27', value: 27 },
  { name: '28', value: 28 },
  { name: '29', value: 29 },
  { name: '30', value: 30 },
  { name: '31', value: 31 }
]
