const NODE_ENV = process.env.NODE_ENV || 'development'

const envObj = {
  dev: {
    NODE_ENV,
    API_URL: process.env.REACT_APP_API_URL || 'https://api.go-ess.com/v1',
    APP_URL: process.env.REACT_APP_APP_URL || 'http://localhost:8080',
    TITLE_PREFIX: process.env.REACT_APP_TITLE_PREFIX || 'ESS',
    WEB_STATE: process.env.REACT_APP_WEB_STATE?.trim(),
    APP_URL_ADMIN:
      process.env.REACT_APP_URL_ADMIN?.trim() || 'http://localhost:8081',
    APP_URL_EMPLOYEE:
      process.env.REACT_APP_URL_EMPLOYEE?.trim() || 'http://localhost:8080',
    TOKEN_SECRET: process.env.REACT_APP_TOKEN_SECRET || 'This is JWT AES TOKEN',
    RECAPTCHA_SITE_KEY:
      process.env.REACT_APP_RECAPTCHA_SITE_KEY ||
      '6LdUtXwcAAAAAJtfBDmx4goahUcwnnVeDRME5v9_'
  },
  prod: {
    NODE_ENV,
    API_URL: process.env.REACT_APP_API_URL,
    APP_URL: process.env.REACT_APP_APP_URL,
    TITLE_PREFIX: process.env.REACT_APP_TITLE_PREFIX,
    WEB_STATE: process.env.REACT_APP_WEB_STATE?.trim(),
    APP_URL_ADMIN: process.env.REACT_APP_URL_ADMIN?.trim(),
    APP_URL_EMPLOYEE: process.env.REACT_APP_URL_EMPLOYEE?.trim(),
    TOKEN_SECRET: process.env.REACT_APP_TOKEN_SECRET,
    RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY
  }
}

module.exports = ['test', 'development'].includes(NODE_ENV)
  ? envObj.dev
  : envObj.prod
