export default {
  GET_ALL_EPAYSLIP: {
    url: `/${process.env.REACT_APP_PAYSLIP_SERVICE_PREFIX}/ePayslip`,
    isPublic: false
  },
  FORGOT_PASSWORD_EPAYSLIP: {
    url: `/${process.env.REACT_APP_PAYSLIP_SERVICE_PREFIX}/ePayslip/forgotPassword`,
    isPublic: false
  }
}
