import env from '@core/config/env'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import Crypto from 'crypto-js'

export const saveToken = ({ accessToken, refreshToken }) => {
  const saveLocalStorage = (name, token) => {
    const encryptToken = Crypto.AES.encrypt(token, env.TOKEN_SECRET).toString()

    localStorage.setItem(name, encryptToken)
  }

  saveLocalStorage('API_TOKEN', accessToken)
  saveLocalStorage('REFRESH', refreshToken)
}

const _decrypt = (name) => {
  const encryptedToken = localStorage.getItem(name)
  if (encryptedToken) {
    try {
      const jwtBytes = Crypto.AES.decrypt(encryptedToken, env.TOKEN_SECRET)
      const jwt = jwtBytes.toString(Crypto.enc.Utf8)

      return jwt.toString()
    } catch (e) {
      localStorage.clear()
    }
  }
}

export const getToken = () => {
  return _decrypt('API_TOKEN')
}

export const getRefreshToken = () => {
  return _decrypt('REFRESH')
}

export const getUser = () => {
  const token = getToken()

  if (token) {
    return jwtDecode(token)
  }
}

export const getRolePermission = (role) => {
  const userData = getUser()

  return userData && userData.permissions.find(({ type }) => type === role)
}

export const fetchTokenByRefreshToken = async () => {
  const refreshToken = getRefreshToken()

  const response = await axios({
    method: 'POST',
    url: env.API_URL + '/auth/auth/refreshToken',
    headers: { authorization: `Bearer ${refreshToken}` }
  }).catch((errors) => {
    return null
  })

  if (response?.data?.data) {
    const tokens = response?.data?.data

    saveToken({
      accessToken: tokens?.access_token,
      refreshToken: tokens?.refresh_token
    })
    return tokens?.access_token
  }
}

export const logout = ({ redirectWith, outSite } = {}) => {
  const refreshToken = getRefreshToken()
  return axios({
    method: 'POST',
    url: env.API_URL + '/auth/auth/logout',
    headers: { authorization: `Bearer ${refreshToken}` }
  }).finally(() => {
    localStorage.clear()
    if (outSite) {
      return location.replace(outSite)
    }
    return location.replace(redirectWith ? `/${redirectWith}` : '/')
  })
}

export const checkCompanyGroup = ({
  historyCompanyGroup,
  userCompanyGroup
}) => {
  // NOTE this historyCompanyGroup  will return string because this is param
  if (!userCompanyGroup || historyCompanyGroup === 'undefined') {
    return logout()
  }

  if (historyCompanyGroup.toUpperCase() !== userCompanyGroup.toUpperCase()) {
    return logout({ redirectWith: historyCompanyGroup })
  }
}
