export default {
  GET_ALL_COMPANY: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/company`,
    isPublic: false
  },
  GET_BY_GROUP_CODE: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/company/getByGroupCode/`,
    isPublic: true
  },
  GET_ADMIN_CONFIG: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/company/setting/`,
    isPublic: true
  },
  GET_CONFIG: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/company/setting/`,
    isPublic: true
  },
  GET_ROLE: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/role/roleType`,
    isPublic: false
  },
  GET_MAIN_SETTING: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/company/getCompanyMainSetting/`,
    isPublic: false
  },
  GET_CHANGE_PASSWORD_SETTING: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/company/getChangePasswordSetting/`,
    isPublic: false
  },
  GET_FORGOT_PASSWORD_SETTING: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/company/getForgotPasswordSetting/`,
    isPublic: false
  },
  GET_PORTAL_SETTING: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/company/getPortalSetting/`,
    isPublic: false
  },
  GET_EPAY_SLIP_SETTING: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/company/getEPayslipSetting/`,
    isPublic: false
  },
  PATCH_CHANGE_PASSWORD_SETTING: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/company/updateChangePasswordSetting/`,
    method: 'PATCH',
    isPublic: false
  },
  PATCH_FORGOT_PASSWORD_SETTING: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/company/updateForgotPasswordSetting/`,
    method: 'PATCH',
    isPublic: false
  },
  PATCH_PORTAL_SETTING: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/company/updatePortalSetting/`,
    method: 'PATCH',
    isPublic: false
  },
  PATCH_MAIN_SETTING: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/company/updateCompanyMainSetting/`,
    method: 'PATCH',
    isPublic: false
  },
  PATCH_EPAY_SLIP_SETTING: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/company/updateEPayslipSetting/`,
    method: 'PATCH',
    isPublic: false
  },
  GET_ALL_NEWS_ADMIN: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/news`,
    method: 'GET'
  },
  GET_NEWS_ADMIN: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/news`,
    method: 'GET'
  },
  GET_ALL_NEWS: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/dashboard/news`,
    method: 'GET'
  },
  GET_NEWS: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/dashboard/news/`,
    method: 'GET'
  },
  CREATE_NEWS: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/news`,
    method: 'POST'
  },
  UPDATE_NEWS: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/news`,
    method: 'PATCH'
  },
  DELETE_NEWS: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/news`,
    method: 'DELETE'
  },
  DELETE_COMPANY: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/company`,
    method: 'DELETE',
    isPublic: false
  },
  GET_ALL_CERTIFICATE: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/eCertificate`,
    isPublic: false
  },
  DELETE_CERTIFICATE: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/eCertificate`,
    method: 'DELETE'
  },
  GET_CERTIFICATE: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/eCertificate/`,
    method: 'GET'
  },
  CREATE_CERTIFICATE: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/eCertificate`,
    method: 'POST'
  },
  UPDATE_CERTIFICATE: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/eCertificate/`,
    method: 'PUT'
  },
  GET_CONFIG_CERTIFICATE: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/eCertificate/conf`,
    method: 'GET'
  },
  GET_COUNTRY_CERTIFICATE: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/eCertificate/country`,
    method: 'GET'
  },
  GET_OCTREE: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/company/ocTree`,
    method: 'GET'
  },
  GET_COMPANY_JOB_MASTER: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/company/jobMaster`,
    method: 'GET'
  },
  GET_STATUS_EMPLOYEE: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/pisEmployee/status`,
    method: 'GET'
  },
  GET_NOTIFICATION: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/notification`
  },
  UPDATE_NOTIFICATION: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/notification/`,
    method: 'PATCH'
  },
  GET_AUDIT_LOG_REPORT: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/auditLog/report/excel`,
    method: 'GET'
  }
}
