export default {
  title: 'E-Leave - Leave Approval',
  form: {
    organization: {
      placeholder: 'Oc Name..',
      label: 'Oc Name'
    },
    'employee-code': {
      placeholder: 'Employee Code..',
      label: 'Employee Code'
    },
    'name-surname': {
      placeholder: 'Name - Surname..',
      label: 'Name - Surname'
    },
    position: {
      placeholder: 'Position..',
      label: 'Position'
    },
    level: {
      placeholder: 'Level..',
      label: 'Level'
    },
    status: {
      placeholder: 'Status..',
      label: 'Status'
    },
    'leave-status': {
      placeholder: 'Leave Status..',
      label: 'Leave Status'
    },
    date: {
      placeholder: 'Date..',
      label: 'Date'
    }
  },
  table: {
    name: 'Name - Surname',
    type: 'Leave Type',
    duration: 'Leave Duration',
    days: 'No. of Days',
    status: 'Status',
    approval: 'Apporval Persons',
    attach: 'Attach',
    action: 'Action'
  },
  modal: {
    approve: 'Are you sure want to Approve ?',
    reject: 'Are you sure want to Reject ?'
  }
}
