export default {
  GET_LEAVE_STATUS: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leave/leaveStatus`
  },
  GET_ALL_LEAVE_GROUP: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leaveGroup`
  },
  GET_LEAVE_GROUP: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leaveGroup/`
  },
  CREATE_LEAVE_GROUP: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leaveGroup`,
    method: 'POST'
  },
  UPDATE_LEAVE_GROUP: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leaveGroup/`,
    method: 'PUT'
  },
  DELETE_LEAVE_GROUP: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leaveGroup`,
    method: 'DELETE'
  },
  GET_LEAVE_REPORT_SETTING: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leaveReportSetting/`,
    method: 'GET'
  },
  UPDATE_LEAVE_REPORT_SETTING: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leaveReportSetting/`,
    method: 'PUT'
  },
  SEARCH_APPROVAL_LIST: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leaveTx/getApproval`,
    method: 'POST'
  },
  GET_TRANSACTION_HISTORY_LIST: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leaveTx/report/`,
    method: 'POST'
  },
  CREATE_DELEGATES: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/delegate/`,
    method: 'POST'
  },
  UPDATE_DELEGATES: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/delegate/`,
    method: 'PATCH'
  },
  DELETE_DELEGATES: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/delegate/`,
    method: 'DELETE'
  },
  GET_DELEGATES: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/delegate/`
  },
  GET_ALL_DELEGATES: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/delegate/`
  },
  POST_LEAVE_TRANSACTION: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leaveTx/report`,
    method: 'POST'
  },
  EXPORT_LEAVE_TRANSACTION: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leaveTx/report/excel`,
    method: 'POST'
  },
  POST_LEAVE_SUMMARY: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leaveSummary`,
    method: 'POST'
  },
  EXPORT_LEAVE_SUMMARY: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leaveSummary/report/excel`,
    method: 'POST'
  },
  GET_ENTITLEMENT: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leaveSummary/entitlement`,
    method: 'GET'
  },
  CREATE_LEAVETX: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leaveTx/`,
    method: 'POST'
  },
  POST_APPROVAL: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leaveTx/approval`,
    method: 'POST'
  },
  GET_LEAVE_TX_BY_PIS_ID: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leaveTx/pisId/`,
    method: 'GET'
  },
  GET_LEAVE_TX: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leaveTx/`,
    method: 'GET'
  },
  EDIT_LEAVETX: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leaveTx/`,
    method: 'PATCH'
  },
  POST_CANCEL_TX: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leaveTx/cancel/`,
    method: 'POST'
  },
  POST_RESEND_TX: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leaveTx/resendEmail/`,
    method: 'POST'
  },
  GET_EMPLOYEE_INFORMATION: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/employeeInformationSetting/`
  },
  UPDATE_EMPLOYEE_INFORMATION: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/employeeInformationSetting/`,
    method: 'PATCH'
  },
  GET_LEAVE_PERIOD: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leavePeriod/`
  },
  CREATE_LEAVE_PERIOD: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leavePeriod`,
    method: 'POST'
  },
  UPDATE_LEAVE_PERIOD: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leavePeriod/action`,
    method: 'POST'
  },
  SEARCH_LEAVE_SUMMARY: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leaveSummary/`,
    method: 'POST'
  },
  LEAVE_SUMMARY_REPORT_EXCEL: {
    url: `${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leaveSummary/report/excel`,
    method: 'POST'
  },
  EDIT_LEAVE_SUMMARY: {
    url: `${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leaveSummary/`,
    method: 'PATCH'
  },
  SEARCH_LEAVE_TYPE: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leavetype`
  },
  APPROVE_EMAIL_LEAVETX: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leaveTx/approval/email`,
    method: 'POST'
  }
}
