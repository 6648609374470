import axios from 'axios'
import { getContentOfBase64, getNameFromPath } from './helper'

export const downloadFileFromUrl = (url = '', fileKey = '') => {
  const fileName = getNameFromPath(fileKey)

  return axios({
    url: url, // your url
    method: 'GET',
    responseType: 'blob'
  }).then((response) => {
    const urls = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = urls
    link.setAttribute('download', fileName) // or any other extension
    document.body.appendChild(link)
    link.click()
  })
}

export const downloadBase64 = (
  base64 = '',
  contentType = 'application/pdf',
  fileName = 'doc.pdf'
) => {
  // application/pdf
  // application/msword
  // image/jpeg
  // image/png

  const linkSource = `data:${contentType};base64,${getContentOfBase64(base64)}`
  const a = document.createElement('a')
  a.href = linkSource
  a.download = fileName
  a.click()
}

export const saveFile = (kr) => {
  const element = document.createElement('a')
  const file = new Blob([kr], {
    type: 'text/plain'
  })
  element.href = URL.createObjectURL(file)
  element.download = 'myFile.pdf'
  document.body.appendChild(element) // Required for this to work in FireFox
  element.click()
}

export const downloadURI = (uri) => {
  const link = document.createElement('a')
  // If you don't know the name or want to use
  // the webserver default set name = ''
  link.setAttribute('download', uri?.name)
  link.href = URL.createObjectURL(uri)
  document.body.appendChild(link)
  link.click()
  link.remove()
}
