export default {
  POST_TOKEN: {
    url: `/${process.env.REACT_APP_AUTH_SERVICE_PREFIX}/recaptcha`,
    method: 'POST',
    isPublic: false
  },
  POST_FORGOT_PASSWORD: {
    url: `/${process.env.REACT_APP_AUTH_SERVICE_PREFIX}/auth/forgotPassword`,
    method: 'POST',
    isPublic: true
  }
}
