export default {
  GET_ALL_WORK_FLOW: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/workflow`
  },
  GET_WORK_FLOW: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/workflow/`
  },
  CREATE_WORK_FLOW: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/workflow/`,
    method: 'POST'
  },
  UPDATE_WORK_FLOW: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/workflow/`,
    method: 'PUT'
  },
  DELETE_WORK_FLOW: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/workflow/`,
    method: 'DELETE'
  }
}
