export default {
  GET_COMPANY_LEAVE_TYPE: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leavetype/`,
    isPublic: false
  },
  DELETE_COMPANY_LEAVE_TYPE: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leavetype/`,
    method: 'DELETE',
    isPublic: false
  },
  ADD_COMPANY_LEAVE_TYPE: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leavetype/`,
    method: 'POST',
    isPublic: false
  },
  EDIT_COMPANY_LEAVE_TYPE: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leavetype/`,
    method: 'PUT',
    isPublic: false
  },
  GET_WORKFLOW: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/workflow`,
    method: 'GET',
    isPublic: false
  },
  GET_CALENDAR_DATA: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/leave/calendar`,
    method: 'GET',
    isPublic: false
  },
  CRON: {
    url: `/${process.env.REACT_APP_LEAVE_SERVICE_PREFIX}/cron`,
    method: 'GET',
    isPublic: false
  }
}
