export const APIS = {
  GENERALS: require('./generalApis').default,
  AUTHENTICATION: require('./authApis').default,
  LEAVE: require('./leaveApi').default,
  USER: require('./userApi').default,
  FORGOT_PASSWORD: require('./forgotPasswordApi').default,
  CHANGE_PASSWORD: require('./changePasswordApi').default,
  FILES: require('./fileApis').default,
  LEAVE_SERVICER: require('./leaveServiceApi').default,
  EPAYSLIP: require('./epayslipApis').default,
  ECERTIFICATE: require('./ecertificateApis').default,
  WORKFLOW: require('./workflowApis').default
}
