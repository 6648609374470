export default {
  GET_PRESIGNED_UPLOAD_URL: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/aws/getUploadSignedUrl`,
    method: 'GET'
  },
  UPLOAD_TERM_OF_SERVICE_FILE: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/company/uploadTerm`,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  },
  GET_PRESIGNED_DOWNLOAD_URL: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/aws/getDownloadSignedUrl`,
    method: 'GET'
  },
  UPLOAD_SSO_FILE: {
    url: `/${process.env.REACT_APP_GENERAL_SERVICE_PREFIX}/company/upload-cert`,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
}
