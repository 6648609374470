import { WEB_STATES } from '@constants/common'
import env from '@core/config/env'
import {
  checkCompanyGroup,
  getUser,
  saveToken as clientLogin
} from '@core/utils'
import { isEmpty } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import jwtDecode from 'jwt-decode'

const withAuth =
  ({ grant }) =>
  (PageComponent) => {
    return ({ location, history, match, ...props }) => {
      const [displayPage, setDisplayPage] = React.useState(false)
      const [userData, setUserData] = React.useState()

      const searchParams = useMemo(
        () => new URLSearchParams(location.search),
        [location.search]
      )

      const checkPublicPage = React.useCallback(
        (webState) => {
          const pathName = location.pathname

          const splitPathName = pathName.split('/')
          // fix after
          const isRootPage = pathName === '/'
          const isLoginPage = Boolean(
            splitPathName.length === 2 && splitPathName[1]
          )
          const isApproveEmailPage = Boolean(
            splitPathName.length === 3 &&
              !splitPathName[2].includes('approve-email') &&
              !splitPathName[2] === 'setting'
          )

          const isChangePasswordPage =
            splitPathName.length === 4 && splitPathName[3] === 'change-password'
          const isForgotPasswordPage =
            splitPathName.length === 4 && splitPathName[3] === 'forgot-password'

          const isChangePasswordAdminPage =
            splitPathName.length === 3 && splitPathName[2] === 'change-password'
          const isForgotPasswordAdminPage =
            splitPathName.length === 3 && splitPathName[2] === 'forgot-password'

          const globalIsPublic =
            isRootPage ||
            isLoginPage ||
            isChangePasswordPage ||
            isForgotPasswordPage ||
            isChangePasswordAdminPage ||
            isForgotPasswordAdminPage ||
            isApproveEmailPage

          // check public page by web state
          // TODO: right now there is not differences in public page
          if (webState === WEB_STATES.MAIN) {
            return globalIsPublic
          } else {
            return globalIsPublic
          }
        },
        [location.pathname]
      )

      const publicAuth = React.useCallback(
        (user) => {
          if (isEmpty(user)) {
            setDisplayPage(true)
          } else {
            if (env.WEB_STATE === WEB_STATES.MAIN) {
              history.replace(
                `/${match.params.companyGroup}/${user.companyId}/dashboard`
              )
            } else {
              history.replace(
                `/${match.params.companyGroup}/self-service/login`
              )
            }
          }
        },
        [history, match.params.companyGroup, location.pathname]
      )

      const privateAuth = React.useCallback(
        (user) => {
          const search = location.search
          const token = new URLSearchParams(search).get('token')
          if (token && !localStorage.getItem('redirect-url')) {
            localStorage.setItem(
              'redirect-url',
              `${history?.location?.pathname}${history?.location?.search}`
            )
          }
          if (isEmpty(user)) {
            window.location.replace(
              `${env.APP_URL_EMPLOYEE}/${match.params.companyGroup}`
            )
          } else {
            checkCompanyGroup({
              historyCompanyGroup: match.params.companyGroup,
              userCompanyGroup: user?._pis?.companyGroupCode
            })
            setUserData(user)
            setDisplayPage(true)
          }
        },
        [
          location.search,
          match.params.companyGroup,
          history?.location?.pathname,
          history?.location?.search
        ]
      )

      const isPublicPage = React.useMemo(() => {
        return checkPublicPage(env.WEB_STATE)
      }, [checkPublicPage])

      const isLoginWithToken = useCallback(() => {
        clientLogin({
          accessToken: searchParams.get('at'),
          refreshToken: searchParams.get('rt')
        })
        const data = jwtDecode(searchParams.get('at'))

        if (searchParams.get('mode') === 'sso') {
          return history.push(
            `/${data._pis.companyGroupCode}/${data.companyId}/dashboard`
          )
        }
        return history.push(`/${data._pis.companyGroupCode}/self-service/login`)
      }, [history, searchParams])

      React.useEffect(() => {
        const user = getUser()
        // NOTE check param have at(access token) and rt(refresh token) mean this is come from hr admin login
        if (
          location.search &&
          searchParams.get('at') &&
          searchParams.get('rt')
        ) {
          if (searchParams.get('approve')) {
            localStorage.setItem('redirect-url', searchParams.get('approve'))
          }
          return isLoginWithToken(searchParams)
        }
        if (isPublicPage) {
          publicAuth(user)
        } else {
          privateAuth(user)
        }
      }, [
        isLoginWithToken,
        isPublicPage,
        location.search,
        privateAuth,
        publicAuth,
        searchParams
      ])

      return displayPage ? (
        <PageComponent
          userData={userData}
          grant={grant}
          {...{ location, history, match, ...props }}
        />
      ) : null
    }
  }

export default withAuth
