export default {
  title: 'การบริหารจัดการวันลา',
  home: 'หน้าหลัก',
  tabs: {
    'employee-infomation': 'ข้อมูลพนักงาน',
    'leave-type': 'ประเภทการลา',
    'leave-group': 'กลุ่มการลา',
    'leave-period': 'รอบการลา',
    'report-setting': 'ตั้งค่ารายงาน'
  },
  'employee-information': {
    form: {
      name: 'ชื่อ-สกุล',
      position: 'ตำแหน่ง',
      email: 'Email'
    }
  },
  'leave-period': {
    title: 'ปี',
    form: {
      year: 'สร้างปี',
      'year-placeholder': 'กรุณาเลือกปี',
      close: 'บังคับดำเนินการ'
    },
    table: {
      'leave-type': 'ประเภทการลา',
      'period-from': 'รอบเริ่มต้น',
      'period-to': 'ถึง',
      'approve-all': 'อนุมัติทั้งหมด',
      'reject-all': 'ไม่อนุมัติทั้งหมด',
      approve: 'อนุมัติ',
      reject: 'ไม่อนุมัติ'
    },
    error: {
      'not-select-value': 'please select a value of Close Period',
      'not-select-approve-reject': 'please select apporval or reject'
    }
  },
  'report-setting': {
    form: {
      status: 'สถานะ',
      leave: 'แสดงจำนวนวันลาที่รายงาน',
      cal: 'จำนวนชั่วโมงมาตรฐานต่อวันสำหรับคำนวณชั่วโมงทำงานล่วงเวลามาเป็นวันลา',
      'by-day': 'ฐาน วัน ชั่วโมง นาที',
      'by-hundred': 'ฐาน 100',
      round: 'การปัดเศษจำนวนวันลา',
      hours: 'ชั่วโมง',
      noround: 'ไม่ปัดเศษ',
      floor: 'ปัดเศษทิ้ง',
      ceil: 'ปัดเศษขึ้นเป็น 1',
      roundup: 'ตั้งแต่ 0.5 ปัดขึ้น',
      rounddown: 'น้อยกว่า 0.5 ปัดลง',
      'speacial-one': 'เงื่อนไขพิเศษ 1',
      policy:
        'ปัดเศษตามเงื่อนไข (มากกว่า 0.5 วัน ปัดเป็น 1 วัน, น้อยกว่าหรือเท่ากับ 0.5 วัน ปัดเป็น 0.5 วัน)'
    }
  },

  'leave-group': {
    'add-leave-group': 'เพิ่มกลุ่มการลา',
    'edit-leave-group': 'แก้ไขกลุ่มการลา',
    'filter-form': {
      'search-placeholder': 'ค้นหากลุ่มการลา',
      'status-placeholder': 'สถานะ',
      'search-label': 'กลุ่มการลา',
      'status-label': 'สถานะ'
    },
    'add-button': 'เพิ่มรายการใหม่',
    modal: {
      title: 'คุณต้องการลบรายการของกลุ่มการลา ใช่หรือไม่',
      accept: 'ใช่',
      cancel: 'ไม่ใช่'
    },
    table: {
      id: 'ลำดับที่',
      code: 'รหัสกลุ่มการลา',
      minLeavePerTime: 'จำนวนวันลาขั้นต่ำต่อครั้ง',
      maxLeavePerTime: 'จำนวนวันลาสูงสุดต่อครั้ง',
      leaveGroupDescriptionTH: 'รายละเอียดเพิ่มเติมกลุ่มการลา (ภาษาไทย)',
      leaveGroupDescriptionEN: 'รายละเอียดเพิ่มเติมกลุ่มการลา (ภาษาอังกฤษ)',
      status: 'สถานะ'
    },
    form: {
      code: 'รหัสกลุ่มการลา',
      status: 'สถานะ',
      'description-th': 'รายละเอียดเพิ่มเติมกลุ่มการลา (ภาษาไทย)',
      'description-en': 'รายละเอียดเพิ่มเติมกลุ่มการลา (ภาษาอังกฤษ)',
      'leave-type-code': 'รหัสประเภทการลา',
      level: 'Leave Code',
      organizational: 'โครงสร้างองค์กร',
      'job-type': 'ระดับงาน',
      job: 'ตำแหน่ง',
      category: 'ประเภท',
      'employee-except': 'ยกเว้นพนักงาน',
      'employee-code': 'รหัสพนักงาน',
      'employee-name': 'ชื่อ - นามสกุล',
      'min-leave': 'จำนวนวันลาขั้นต่ำต่อครั้ง',
      'max-leave': 'จำนวนวันลาสูงสุดต่อครั้ง'
    }
  },
  'company-table': require('./CompanyTable.js').default,
  'leave-type': {
    'add-button': 'เพิ่มรายการใหม่',
    'cal-leave': 'คำนวนสิทธิ์การลา',
    'filter-form': {
      'code-placeholder': 'ค้นหาประเภทการลา',
      'status-placeholder': 'สถานะ',
      'code-label': 'ประเภทการลา',
      'status-label': 'สถานะ'
    },
    table: {
      id: 'ลำดับที่',
      'leave-type-code': 'รหัสประเภทการลา',
      'leave-type-description-th': 'รายละเอียดประเภทการลา (ภาษาไทย)',
      'leave-type-description-en': 'รายละเอียดประเภทการลา (ภาษาอังกฤษ)',
      status: 'สถานะ'
    },
    add_title: 'เพิ่มประเภทการลา',
    edit_title: 'แก้ไขข้อมูลประเภทการลา',
    'second-section-title': 'ตั้งค่าวันลา',
    'set-detail-block-leave': 'กำหนดรายละเอียด Block Leave',
    'block-leave-detail-title': 'กำหนดรายละเอียด',
    'fifth-section-title': 'ตั้งค่าการอนุญาต',
    modal: {
      title: 'คุณต้องการลบรายการของประเภทการลา ใช่หรือไม่',
      button: {
        cancel: 'ยกเลิก'
      },
      accept: 'ยืนยัน'
    },
    form: {
      company: 'บริษัท',
      'company-placeholder': 'ชื่อบริษัท',
      status: 'สถานะ',
      'status-placeholder': 'สถานะ',
      code: 'รหัส ประเภทการลา',
      'code-placeholder': 'รหัส ประเภทการลา',
      nameTH: 'ชื่อ ประเภทการลา TH',
      'nameTH-placeholder': 'ชื่อ ประเภทการลา TH',
      nameEN: 'ชื่อ ประเภทการลา EN',
      'nameEN-placeholder': 'ชื่อ ประเภทการลา EN',
      gender: 'เพศ',
      'gender-placeholder': 'เพศ',
      officerType: 'สถานะพนักงาน',
      'officerType-placeholder': 'สถานะพนักงาน',
      active: 'ใช้งาน',
      inactive: 'ไม่ใช้งาน',
      male: 'ชาย',
      female: 'หญิง',
      'all-gender': 'ทุกเพศ',
      'all-employee': 'พนักงานทุกสถานะ',
      'current-employee': 'พนักงานปัจจุบัน',
      'newbie-employee': 'พนักงานใหม่',
      'intern-employee': 'พนักงานทดลองงาน',
      'newbie-and-intern-employee': 'พนักงานใหม่และพนักงานทดลองงาน',
      maxLeaveOverLifeTime: 'จำนวนสูงสุดตลอดอายุงาน (ครั้ง)',
      'maxLeaveOverLifeTime-placeholder': 'จำนวนสูงสุดตลอดอายุงาน (ครั้ง)',
      maxLeavePerYear: 'จำนวนสูงสุดต่อปี (ครั้ง)',
      'maxLeavePerYear-placeholder': 'จำนวนสูงสุดต่อปี (ครั้ง)',
      minLeaveDayPerTime: 'จำนวนวันลาขั้นต่ำต่อครั้ง',
      'minLeaveDayPerTime-placeholder': 'ระบุจำนวน',
      maxLeaveDayPerTime: 'จำนวนวันลาสูงสุดต่อครั้ง',
      'maxLeaveDayPerTime-placeholder': 'ระบุจำนวน',
      blockLeaveType: 'กำหนด Block Leave',
      select: 'กำหนด',
      unselect: 'ไม่กำหนด',
      workTimeCalculationType: 'การเริ่มนับอายุงาน',
      startday: 'วันที่เริ่มงาน',
      passpromotion: 'วันผ่านทดลองงาน',
      permissionLeave: 'การให้สิทธิ์การลา',
      'leave-cycle': 'รอบการลา',
      'start-word-date': 'ณ วันที่เริ่มงาน',
      'probation-expiration-date': 'ณ วันที่พ้นการทดลองงาน',
      'every-start-month': 'ให้สิทธิ์สะสม (วันแรกของเดือน)',
      'every-end-month': 'ให้สิทธิ์สะสม (วันสุดท้ายของเดือน)',
      'service-life': 'ตามอายุงาน',
      'first-leave-date': 'เริ่มลาครั้งแรก',
      identify: 'ระบุจำนวน',
      days: 'วัน',
      permissionCalculation: 'การคำนวณสิทธิ์การลา',
      'full-integer': 'ให้เต็มจำนวน',
      'calculate-proportions': 'คำนวณสัดส่วน',
      roundDay: 'การคำนวณตามสัดส่วน',
      'round-start-work': 'คำนวณตามสัดส่วนนับจากวันที่เริ่มงาน',
      'round-pass-probation': 'คำนวณตามสัดส่วนนับจากวันผ่านทดลองงาน',
      roundType: 'การปัดเศษสิทธิ์ลา',
      round: 'ปัดเศษ',
      noround: 'ไม่ปัดเศษ',
      floor: 'ปัดเศษทิ้ง',
      ceil: 'ปัดเศษขึ้นเป็น 1',
      roundup: 'ตั้งแต่ 0.5 ปัดขึ้น',
      rounddown: 'น้อยกว่า 0.5 ปัดลง',
      'speacial-one': 'เงื่อนไขพิเศษ 1',
      policy:
        'ปัดเศษตามเงื่อนไข (มากกว่า 0.5 วัน ปัดเป็น 1 วัน, น้อยกว่าหรือเท่ากับ 0.5 วัน ปัดเป็น 0.5 วัน)',
      'only-max-lifetime': 'ระบุเฉพาะจำนวนสูงสุดตลอดอายุงาน',
      'only-max-peryear': 'ระบุเฉพาะจำนวนสูงสุดต่อปี',
      'only-both': 'ระบุทั้งจำนวนสูงสุดตลอดอายุงาน และจำนวนสูงสุดต่อปี',
      'not-select': 'ไม่ระบุทั้งจำนวนสูงสุดตลอดอายุงาน และจำนวนสูงสุดต่อปี',
      leaveDayCalculationType: 'การนับเวลา',
      'not-include-dayoff': 'ไม่นับวันหยุด',
      weekend: 'นับเฉพาะวันหยุดประจำสัปดาห์',
      'weekend-dayoff':
        'นับวันหยุดทั้งหมด (วันหยุดประจำสัปดาห์ + วันหยุดนักขัตฤกษ์)',
      order: 'ลำดับ',
      'min-leave-label': 'จำนวนวันลาขั้นต่ำ',
      'min-leave-placeholder': 'จำนวนวันลาขั้นต่ำ',
      'start-date-label': 'ตั้งแต่วันที่',
      'end-date-label': 'ถึงวันที่',
      'round-month-label': 'การปัดเศษเดือน',
      'round-to-day': 'ปัดให้เป็น (วัน)',
      date: 'วันที่',
      month: 'เดือน',
      'organize-placeholder': 'โครงสร้างองค์กร',
      'organize-label': 'โครงสร้างองค์กร',
      'position-placeholder': 'ตำแหน่งงาน',
      'position-label': 'ตำแหน่งงาน',
      'level-placeholder': 'ระดับงาน',
      'level-label': 'ระดับงาน',
      'period-of-employment': 'ช่วงอายุงานที่สามารถใช้สิทธิ์ได้',
      'number-placeholder': 'number',
      to: 'ถึง',
      from: 'จาก',
      entitlement: 'ได้รับสิทธิ์',
      'forward-type-label': 'ยกยอดสะสม',
      'expire-type-label': 'กำหนดวันที่สามารถใช้สิทธิ์ลา (กรณียกยอด)',
      isAllowOverdue: 'อนุญาตให้ลาเกิน',
      allow: 'อนุญาต',
      notallow: 'ไม่อนุญาต',
      isActiveForbiddenDate: 'กำหนดช่วงห้ามลา',
      startDate: 'ตั้งแต่วันที่',
      endDate: 'ถึงวันที่',
      prospectLeave: 'กำหนดการลาล่วงหน้า',
      retrospectLeave: 'กำหนดการลาย้อนหลัง',
      isRequireAttachment: 'การบังคับแนบเอกสาร',
      isRequireReason: 'การบังคับใส่เหตุผล',
      force: 'กำหนด',
      notforce: 'ไม่กำหนด',
      reasonTextHeader: 'ข้อความแจ้งการระบุเหตุผลการลา',
      isAllowUrgentLeave: 'อนุญาตให้ลาฉุกเฉิน',
      inMonthCondition: 'วันที่ลาต้องอยู่ในเดือน',
      'no-need-in-month': 'ไม่ต้องอยู่ในเดือน',
      birthday: 'วันเกิดพนักงาน',
      partnerbirthday: 'วันเกิดคู่สมรส',
      chlidbirthday: 'วันเกิดบุตร',
      'payment-label': 'การจ่ายเงิน',
      unpay: 'ไม่จ่าย',
      pay: 'จ่าย',
      'leave-period-label': 'รอบการลา',
      dependcalendar: 'ตามปฏิทิน',
      identifydate: 'ระบุวัน/เดือน',
      shelveDayConfig: 'การตัดสิทธิลาข้ามปี',
      undefined1: 'ไม่ระบุ',
      'start-from-leave-year': 'ตัดสิทธิ์ปีที่เริ่มลา',
      'seperate-year': 'ตัดสิทธิ์แยกปี',
      'display-priority-label': 'ลำดับการแสดง',
      'display-priority-placeholder': 'ระบุลำดับการแสดง',
      'workflow-approve': 'Workflow อนุมัติ',
      'workflow-approve-placeholder': 'เลือก Workflow อนุมัติ',
      'workflow-cancel': 'Workflow ยกเลิกอนุมัติ',
      'workflow-cancel-placeholder': 'เลือก Workflow ยกเลิกอนุมัติ'
    }
  }
}
