export default {
  POST_LOGIN: {
    url: `/${process.env.REACT_APP_AUTH_SERVICE_PREFIX}/auth/login`,
    isPublic: true,
    method: 'POST'
  },
  POST_RESIGN_OTP: {
    url: `/${process.env.REACT_APP_AUTH_SERVICE_PREFIX}/auth/resignOtp`,
    isPublic: true,
    method: 'POST'
  },
  POST_VERIFY_OTP: {
    url: `/${process.env.REACT_APP_AUTH_SERVICE_PREFIX}/auth/verifyOtp`,
    isPublic: true,
    method: 'POST'
  },
  POST_INIT_PASSWORD: {
    url: `/${process.env.REACT_APP_AUTH_SERVICE_PREFIX}/auth/initPassword`,
    isPublic: true,
    method: 'POST'
  },
  GET_ALL_USER: {
    url: `/${process.env.REACT_APP_AUTH_SERVICE_PREFIX}/user/getAllUser`,
    method: 'POST'
  },
  GET_EMPLOYEE_STATUS: {
    url: `/${process.env.REACT_APP_AUTH_SERVICE_PREFIX}/user/pisEmployeeStatus`
  },
  GET_JWT_FROM_SSO_TOKEN: {
    url: `/${process.env.REACT_APP_AUTH_SERVICE_PREFIX}/auth/sso-token`,
    isPublic: false,
    method: 'GET'
  }
}
